var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-content", [
    _c(
      "div",
      { attrs: { id: "core-view" } },
      [
        _c(
          "v-fade-transition",
          { attrs: { mode: "out-in" } },
          [_c("router-view")],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }