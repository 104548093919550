var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      attrs: {
        id: "app-drawer",
        app: "",
        right: "",
        temporary: "",
        "mobile-break-point": _vm.mobileBreakPoint,
        width: "300"
      },
      model: {
        value: _vm.inputValue,
        callback: function($$v) {
          _vm.inputValue = $$v
        },
        expression: "inputValue"
      }
    },
    [
      _c(
        "v-layout",
        { attrs: { tag: "v-list", column: "" } },
        _vm._l(_vm.navigationItems, function(navigationItem) {
          return _c(
            "v-list-tile",
            {
              key: navigationItem.name,
              staticClass: "v-list-item",
              attrs: {
                to: navigationItem.path,
                "active-class": "success white--text",
                avatar: ""
              }
            },
            [
              _c("v-list-tile-title", [
                _vm._v(_vm._s(navigationItem.meta.title))
              ])
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }