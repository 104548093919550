var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-footer",
    {
      staticClass: "pa-3",
      attrs: { id: "core-footer", fixed: "", color: "white" }
    },
    [
      _c("span", { staticClass: "font-weight-line copyright" }, [
        _vm._v(
          "© " +
            _vm._s(_vm.copyrightInfo) +
            " - Version: " +
            _vm._s(_vm.versionInfo)
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }