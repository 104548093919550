var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": _vm.maxWidth,
        lazy: "",
        scrollable: "",
        fullscreen: _vm.isResponsive,
        persistent: "",
        "no-click-animation": ""
      },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.onEsc.apply(null, arguments)
        }
      },
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v(_vm._s(_vm.modalTitle))]),
          _c("v-divider"),
          _c("v-card-text", [_vm._t("default")], 2),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "grey", flat: "" },
                  on: { click: _vm.onCancel }
                },
                [_vm._v(_vm._s(_vm.cancelButtonText))]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", flat: "" },
                  on: { click: _vm.onSave }
                },
                [_vm._v(_vm._s(_vm.saveButtonText))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }