var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-toolbar",
    {
      attrs: {
        id: "core-toolbar",
        "clipped-right": "",
        fixed: "",
        dark: "",
        app: ""
      }
    },
    [
      _c(
        "div",
        { staticClass: "v-toolbar-title grow" },
        [
          _c("v-toolbar-title", { staticClass: "justify-between" }, [
            _vm._v("WL Plastics")
          ])
        ],
        1
      ),
      _vm._l(_vm.navigationItems, function(navigationItem) {
        return _c(
          "v-toolbar-items",
          {
            key: navigationItem.name,
            staticClass: "hidden-sm-and-down",
            attrs: { "active-class": "success" }
          },
          [
            _c("v-btn", { attrs: { to: navigationItem.path, flat: "" } }, [
              _vm._v(_vm._s(navigationItem.meta.title))
            ])
          ],
          1
        )
      }),
      _c(
        "v-btn",
        {
          staticClass: "hidden-md-and-up default v-btn--simple",
          attrs: { dark: "", icon: "" },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.onClickBtn.apply(null, arguments)
            }
          }
        },
        [_c("v-icon", [_vm._v("mdi-menu")])],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }