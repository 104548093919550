var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      attrs: { color: _vm.snackbar.Color, top: true },
      model: {
        value: _vm.snackbar.isShown,
        callback: function($$v) {
          _vm.$set(_vm.snackbar, "isShown", $$v)
        },
        expression: "snackbar.isShown"
      }
    },
    [
      _vm._v("\n  " + _vm._s(_vm.snackbar.message) + "\n  "),
      _c(
        "v-btn",
        { attrs: { dark: "", flat: "" }, on: { click: _vm.hideSnackbar } },
        [_vm._v("Close")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }