var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return Object.keys(_vm.backendErrorRecord).length !== 0
    ? _c("v-alert", { attrs: { dense: "", border: "left", type: "error" } }, [
        _c(
          "ul",
          _vm._l(_vm.backendErrorRecord, function(value, key) {
            return _c("li", { key: key }, [
              _vm._v("\n      " + _vm._s(key) + ":\n      "),
              _c(
                "ul",
                _vm._l(value, function(text) {
                  return _c("li", { key: text }, [_vm._v(_vm._s(text))])
                }),
                0
              )
            ])
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }