var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "v-container",
    { attrs: { "grid-list-md": "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "red darken-3 white--text subheading",
                      attrs: { "primary-title": "" }
                    },
                    [_c("span", { staticClass: "title" }, [_vm._v("Oops!")])]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      "\n          Looks like you're in uncharted territory. We don't know about this page yet.\n          Luckily, we know the way back.\n        "
                    )
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "red darken-3 white--text",
                          attrs: { flat: "", to: "/" }
                        },
                        [_vm._v("Back")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }